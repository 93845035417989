import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueCookies from 'vue-cookies'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  state: {
    //存放的键值对就是所要管理的状态
    name: sessionStorage.getItem('menu'),
    env: (sessionStorage.getItem('env') &&
      JSON.parse(sessionStorage.getItem('env'))) || {
      cos: process.env.VUE_APP_STATIC,
      domain: process.env.VUE_APP_BASE_HOST
    },
    imgUrl: 'https://test.yfchuhai.com/admin/Upload/upload',
    btn_if: JSON.parse(localStorage.getItem('but')) || [],
    config: null,
    NormalList: null,
    phone: VueCookies.get('phone') || '',
    Did: '',
    counts: 0,
    enterprise_config: {}
  },
  getters: {
    imgUrl(state) {
      return 'https://' + state.env.domain + '/admin/Upload/upload'
    }
  },
  mutations: {
    getConfiguration(state) {
      axios.get('/admin/User/getConfig').then(({ data: res }) => {
        if (res.errorCode == 200) {
          state.config = res.data
        }
      })
      axios
        .get('/admin/WechatAssistant/getNormalList')
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            state.NormalList = res.data
          }
        })
      axios
        .get('/admin/Common/getConfigDictionaries?config=enterprise')
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            state.enterprise_config = res.data
          }
        })
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit('updateValue', payload)
    },
    getconfig() {},
    checkGraphics() {
      return new Promise(resolve => {
        // const langtion = 'zh-hk'
        const langtion = 'zh'
        const captchaId = '2045288608' //腾讯滑块验证码appid
        // 生成一个滑块验证码对象
        const captcha = new TencentCaptcha(captchaId, async res => {
          if (res.ret === 0) {
            // 成功
            resolve(res)
          }
        })
        //  滑块多语言调用
        captcha.initOpts.forceLang = langtion
        captcha.langFun()
        // 滑块显示
        captcha.show()
      })
    },
    getProvince() {
      return new Promise(async resolve => {
        const { data: res } = await axios.get('/admin/Common/getArea')
        resolve(res)
      })
    },
    getCity(st, val) {
      return new Promise(async resolve => {
        const { data: res } = await axios.get('/api/Common/getArea?code=' + val)
        if (res.errorCode == 200) {
          resolve(res)
        }
      })
    }
  }
})

export default store
