<template>
  <div
    v-loading="loading"
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth }"
  >
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <!-- <div class="editor-custom-btn-container">
      <editorImage
        color="#1890ff"
        class="editor-upload-btn"
        @successCBK="imageSuccessCBK"
      />
    </div> -->
  </div>
</template>

<script>
// import {
//   getQiniuCommonToken,
//   uploadImg
// } from '@/api/qiniu.model'
// import {
//   getQiNiuKeyCommon,
// } from '@/utils'
import { uploadFile } from '@/api/upload.model'
import { isExternal } from '@/utils'
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
// import editorImage from './components/EditorImage'
import plugins from './plugins'
import toolbar from './toolbar'
import load from './dynamicLoadScript'

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
const tinymceCDN = './tinymce/tinymce.min.js'

import { convertCloudStorageUrl } from '@/utils'

export default {
  name: 'Tinymce',
  // components: {
  //   editorImage
  // },
  props: {
    id: {
      type: String,
      default: function() {
        return (
          'vue-tinymce-' +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + '')
        )
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    }
  },
  data() {
    return {
      loading: true,
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        en: 'en',
        zh: 'zh_CN',
        es: 'es_MX',
        ja: 'ja'
      },
      qiniuReqData: {
        token: null,
        key: null
      }
      // imgBaseUrl: process.env.VUE_APP_QINIU_IMG_BASE_URL_COMMON + '/'
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.loading = true
        this.$nextTick(() => {
          window.tinymce.get(this.tinymceId).setContent(val || '')
          this.loading = false
        })
      }
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      load(tinymceCDN, err => {
        if (err) {
          this.$message.error(err.message)
          this.loading = false
          return
        }
        this.initTinymce()
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList['zh'],
        height: this.height,
        body_class: 'panel-body',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        convert_urls: false,
        file_picker_types: 'media',
        end_container_on_empty_block: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        fontsize_formats: '10px 12px 14px 16px 18px 20px',
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        powerpaste_word_import: 'merge', // merge/clean
        powerpaste_allow_local_images: true, // 允许带图片
        automatic_uploads: true,
        images_reuse_filename: true,
        relative_urls: false,
        remove_script_host: false,
        paste_data_images: true,
        document_base_url:
          process.env.VUE_APP_IMG_URL + process.env.VUE_APP_STATIC_DIR,

        // images_upload_url: process.env.VUE_APP_QINIU_BASE_URL,
        // images_upload_base_path: this.imgBaseUrl,
        init_instance_callback: editor => {
          // if (_this.value) {
          editor.setContent(_this.value)
          // }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            // console.log('NodeChange Change KeyUp SetContent')
            _this.hasChange = true
            _this.$emit('input', editor.getContent())
          })
        },

        video_template_callback: function(data) {
          return (
            '<video width="' +
            100 +
            '%" height="' +
            'auto"' +
            (data.poster ? ' poster="' + data.poster + '"' : '') +
            ' controls="controls">\n' +
            '<source src="' +
            data.source +
            '"' +
            (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') +
            ' />\n' +
            (data.altsource
              ? '<source src="' +
                data.altsource +
                '"' +
                (data.altsourcemime
                  ? ' type="' + data.altsourcemime + '"'
                  : '') +
                ' />\n'
              : '') +
            '</video>'
          )
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', e => {
            _this.fullscreen = e.state
          })
        },
        // async images_dataimg_filter(img, success, fail) {//定义一个自定义过滤器处理base64图像转blobs的逻辑。
        // },

        // urlconverter_callback(url, node, on_save, name) {
        //   console.log('urlconverter_callback', url, node, on_save, name)
        //   // log
        //   if (url.includes('base64')) {
        //     return url
        //   } else {
        //     return url
        //   }
        // },
        // images_dataimg_filter: function(img) {
        //   return img.hasAttribute('internal-blob')
        // },
        // images_upload_handler: async (blobInfo, success, failure) => {
        //   // progress(0)
        //   // console.log('images_upload_handler', blobInfo.blob())
        //   // 通过七牛上传
        //   // const fileName = blobInfo.filename()
        //   // this.qiniuReqData.key = getQiNiuKeyCommon(fileName)
        //   // const formData = new FormData()
        //   // formData.append('file', blobInfo.blob())
        //   // formData.append('token', this.qiniuReqData.token)
        //   // formData.append('key', this.qiniuReqData.key)
        //   // const res = await uploadImg(formData)
        //   // success(this.imgBaseUrl + res.key)
        //   // 通过后台上传
        //   // try {
        //   const formData = new FormData()
        //   formData.append('file', blobInfo.blob())
        //   formData.append('action', sessionStorage.getItem('action'))
        //   const res = await uploadFile(formData)
        //   const url = res.data.url
        //     ? process.env.VUE_APP_IMG_URL +
        //       process.env.VUE_APP_STATIC_DIR +
        //       res.data.url
        //     : 'de'

        //   // console.log(res)
        //   success(url)
        //   return
        //   // progress(100)
        // }
        images_upload_handler: async function(
          blobInfo,
          success,
          failure,
          progress
        ) {
          // progress(0)
          // var formData
          // var file = blobInfo.blob() //转化为易于理解的file对象
          // formData = new FormData()
          // formData.append('file', file)
          // formData.append('action', sessionStorage.getItem('action'))
          // _this.$http
          //   .post(
          //     process.env.VUE_APP_BASE_URL + '/admin/Upload/upload',
          //     formData
          //   )
          //   .then(({ data: res }) => {
          //     succFun(
          //       process.env.VUE_APP_IMG_URL +
          //         process.env.VUE_APP_STATIC_DIR +
          //         res.data.url
          //     )
          //   })
          try {
            const formData = new FormData()
            formData.append('file', blobInfo.blob())
            formData.append('action', sessionStorage.getItem('action'))
            const res = await uploadFile(formData)
            const url = res.data.url
              ? process.env.VUE_APP_IMG_URL +
                process.env.VUE_APP_STATIC_DIR +
                res.data.url
              : 20
            // if(res.error)
            success(url)

            return
            progress(100)
          } catch (e) {
            if (!e.handled) {
              this.$message.error(e.message)
            }
          }
        },
        // 整合七牛上传
        images_dataimg_filter(img) {
          setTimeout(() => {
            const $image = $(img)
            $image.removeAttr('width')
            $image.removeAttr('height')
            if ($image[0].height && $image[0].width) {
              $image.attr('data-wscntype', 'image')
              $image.attr('data-wscnh', $image[0].height)
              $image.attr('data-wscnw', $image[0].width)
              $image.addClass('wscnph')
            }
          }, 0)
          return img
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      this.loading = true
      window.tinymce.get(this.tinymceId).setContent(value)
      this.loading = false
    },
    getContent() {
      return window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      const _this = this
      arr.forEach(v => {
        window.tinymce
          .get(_this.tinymceId)
          .insertContent(`<img class="wscnph" src="${v.url}" >`)
      })
    },
    async fetchQiniuToken() {
      const res = await getQiniuCommonToken()
      const { uptoken } = res.data
      this.qiniuReqData.token = uptoken
    }
  }
}
</script>

<style scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container >>> .mce-fullscreen {
  z-index: 10000;
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
/* /deep/ */
.mce-branding {
  display: none !important;
}
</style>
<style>
.mce-throbber {
  display: none;
}
.mce-container-body .mce-txt {
  width: 60px;
}
</style>
