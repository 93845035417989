<template>
  <div class="login_container">
    <div class="login_box">
      <img src="../assets/logo.png" alt="" class="img" />
      <h1 style="text">扬帆出海后台管理系统</h1>
      <el-form
        label-width="0px"
        class="login_form"
        :model="login_form"
        :rules="rules"
        ref="loginFormRef"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            prefix-icon="iconfont icon-user"
            v-model="login_form.username"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            prefix-icon="iconfont icon-3702mima"
            v-model="login_form.password"
            type="password"
          ></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">{{
            btn ? '登录' : 'loading..'
          }}</el-button>
          <el-button type="info" @click="resetloginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'
// import 'https://cdn.staticfile.org/jquery-cookie/1.4.1/jquery.cookie.min.js'
export default {
  data() {
    return {
      // 这是登陆
      login_form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' }
          // { min: 3, max: 10, message: '长度在 3 到 10个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
          { min: 6, max: 15, message: '长度在 6 到 15个字符', trigger: 'blur' }
        ]
      },
      btn: true
    }
  },
  methods: {
    resetloginForm() {
      // console.log(this);
      this.$refs.loginFormRef.resetFields()
    },
    login() {
      if (this.btn) {
        this.$refs.loginFormRef.validate(async valid => {
          if (!valid) return
          const graphicsRes = await this.$store.dispatch('checkGraphics')
          var { ticket } = graphicsRes
          // console.log(graphicsRes)
          const res = await this.$http.post('/admin/AuthMember/login', {
            ...this.login_form,
            ticket: ticket,
            randStr: graphicsRes.randstr
          })
          if (res.data.errorCode != 200) {
            this.btn = true
            return this.$message.error(res.data.message)
          }

          this.$message.success(res.data.message)
          // console.log(res)
          sessionStorage.setItem('token', res.data.data.token)
          sessionStorage.setItem('username', res.data.data.username)
          const meu = res.data.data.authAccessList.menus
          const but = res.data.data.authAccessList.buttons
          sessionStorage.setItem('menu', JSON.stringify(meu))
          this.$cookies.set('token', res.data.data.token, '0')
          // this.$cookies.set('username', res.data.data.username)
          this.$cookies.set('phone', res.data.data.phone)
          this.$cookies.set('username', res.data.data.realName)
          // this.$cookies.set('menu', JSON.stringify(meu))
          localStorage.setItem('menu', JSON.stringify(meu))
          localStorage.setItem('but', JSON.stringify(but))
          this.$store.state.btn_if = JSON.stringify(but)
          // console.log(this.$cookies.get('menu'));
          // console.log(sessionStorage.getItem('menu'));
          // this.$cookies.set('menu', JSON.stringify(meu))
          this.$store.state.name = meu
          // console.log(meu);
          // console.log();
          // console.log(JSON.parse(this.$cookies.get('menu')));
          this.$router.push('/home')
          this.btn = true
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  background-color: #7e9bb9;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: space-around;
}
.bc {
  width: 100%;
  height: 100%;
}
h1 {
  text-align: center;
  line-height: 100px;
  font-size: 25px;
  font-weight: bold;
}
.img {
  position: absolute;
  top: -70px;
  left: 130px;
}
</style>
