export default {
  install(Vue) {
    // Vue.prototype.$toTop = function(val) {
    //   console.log(val)
    //   if (val == 201003) {
    //     this.$router.push('/login')
    //   }
    // }
    Vue.prototype.$toData = function(value) {
      if (typeof value == 'object') {
        if (!value) {
          return false
        }
        if (value.length) {
          return !!value.length
        } else {
          return !!Object.keys(value).length
        }
      } else {
        return !!value
      }
    }
    Vue.prototype.$unique = function(arr) {
      return [...new Set(arr)]
    }
  }
}
