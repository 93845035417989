import Vue from 'vue'

// import { Button, Form, FormItem, Icon, Input } from 'element-ui'
import sd from 'element-ui'
// import { Message } from 'element-ui'
Vue.use(sd)
// Vue.use(Form)
// Vue.use(Input)
// Vue.use(FormItem)
// Vue.use(Icon)
// Vue.prototype.$message = Message